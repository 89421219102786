const fullTeamNames = {
    TOR: "Toronto Maple Leafs",
    FLA: "Florida Panthers",
    COL: "Colorado Avalanche",
    CGY: "Calgary Flames",
    PHI: "Philadelphia Flyers",
    VAN: "Vancouver Canucks",
    NJD: "New Jersey Devils",
    NYR: "New York Rangers",
    MTL: "Montreal Canadiens",
    SJS: "San Jose Sharks",
    BUF: "Buffalo Sabres",
    PIT: "Pittsburgh Penguins",
    STL: "St. Louis Blues",
    CAR: "Carolina Hurricanes",
    NSH: "Nashville Predators",
    DAL: "Dallas Stars",
    OTT: "Ottawa Senators",
    EDM: "Edmonton Oilers",
    NYI: "New York Islanders",
    VGK: "Vegas Golden Knights",
    TBL: "Tampa Bay Lightning",
    BOS: "Boston Bruins",
    MIN: "Minnesota Wild",
    CBJ: "Columbus Blue Jackets",
    DET: "Detroit Red Wings",
    WSH: "Washington Capitals",
    ARI: "Arizona Coyotes",
    ANA: "Anaheim Ducks",
    CHI: "Chicago Blackhawks",
    LAK: "Los Angeles Kings",
    SEA: "Seattle Kraken",
    WPG: "Winnipeg Jets"
};

module.exports = fullTeamNames;
